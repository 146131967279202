import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
const pinia = createPinia();

import router from './router';
import { createPinia } from 'pinia';
import { httpClient } from '@/core/http/api.http.service';
import { useStore } from '@/store/index';

//Interceptors
import '@/core/interceptors/auth.interceptor';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';
import DateFnsAdapter from '@date-io/date-fns';
import enUS from 'date-fns/locale/en-US';
import ptBr from 'date-fns/locale/pt-BR';

const vuetify = createVuetify({
  components,
  directives,
  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: enUS,
      ptBR: ptBr,
    },
  },
});

//v-mask
import { VueMaskDirective } from 'v-mask';
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
};

import { JsonEditorPlugin } from 'vue3-ts-jsoneditor';
import TipoCanal from '@/components/shared/tipo-canal/TipoCanal.vue';
import LoadingComponent from '@/components/shared/loading/LoadingComponent.vue';
import ImportacaoComponent from '@/components/shared/importacao/ImportacaoComponent.vue'

import * as Sentry from '@sentry/vue';

const app = createApp(App)
  .component('TipoCanal', TipoCanal)
  .component('Loading', LoadingComponent)
  .component('Importacao', ImportacaoComponent)
  // .component('DatePicker', DatePickerComponent)
  .use(pinia)
  .directive('mask', vMaskV3)
  .use(JsonEditorPlugin, {
    options: {},
  })
  .use(vuetify);

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     app,
//     dsn: 'https://8ee771f3ee5bc46ebfe1e0ea8aa9c006@o1272315.ingest.us.sentry.io/4507810348793856',
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     tracesSampleRate: 1.0, 
//     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     replaysSessionSampleRate: 0.1, 
//     replaysOnErrorSampleRate: 1.0, 
//   });
// }

app.use(router).mount('#app');
