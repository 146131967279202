import { httpClient } from "@/core/http/api.http.service";
import router from "@/router/index";
import { useStore } from "@/store/index";

httpClient.interceptors.request.use(
  (config) => {
    const store = useStore();
    const bearerToken = store.token;

    const token = bearerToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      router.push("/login"); // Use vue-router to redirect to login
    }
    return Promise.reject(error);
  }
);
