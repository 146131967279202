<template>
  <v-dialog v-model="dialog" width="50%" overlay-opacity="0.8">
    <v-card>
      <v-card-text>
        <div v-if="!model.upload">
          <div class="d-flex mb-5">
            <div class="mt-3 font-weight-medium">{{title}} </div>
            <v-spacer></v-spacer>
            <v-btn
              :loading="downloadModelo"
              rounded="xl"
              color="#715FDE"
              variant="tonal"
              class="mt-2"
              @click="downloadModel">
              <v-icon>mdi-download</v-icon> Download modelo</v-btn
            >

            <v-btn flat icon class="ml-2" @click="onHandlerClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-alert
            border="bottom"
            color="primary"
            variant="tonal"
            icon="mdi-database">
            Selecione ou arraste um arquivo excel <strong>(.xlsx)</strong> para
            iniciar o upload no tamanho máximo de 5.00 MB, clicando no botão
            abaixo. Após finalizado o procedimento, clique em Importar. Esse
            processo de importação pode demorar até 30 minutos, dependendo da
            quantidade de registros no arquivo.
          </v-alert>

          <div
            class="mt-5 pa-4"
            style="border: 2px dashed #ccc; border-radius: 10px">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="firstRowIsHeader"
                color="primary"
                label="A primeira linha é o cabeçalho?"></v-checkbox>
            </div>

            <div class="mt-n4">
              <v-file-input
                label="Selecione um arquivo para iniciar a importação"
                counter
                placeholder="Select your files"
                prepend-icon="mdi-upload"
                show-size
                chips
                color="primary"
                clearable
                @change="onSelectedFiles"
                v-model="files"></v-file-input>
            </div>
          </div>
        </div>

        <div v-if="model.upload">
          <v-card color="#F0F4FE" flat rounded="xl">
            <v-card-text>
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span class="font-weight-medium text-h6">
                    {{ model.fileName }}
                  </span>
                  <div>
                    <v-icon color="#0F6CBC">mdi-database</v-icon>
                    Total de registros a serem importados:
                    <span class="font-weight-black">
                      {{ model.totalRows }}
                    </span>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-2"
                  @click="onHandlerCancelImport"
                  variant="tonal"
                  v-if="model.upload"
                  rounded="xl"
                  :loading="loadingCancelar"
                  color="red"
                  ><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar
                  importação</v-btn
                >
              </div>
            </v-card-text>
          </v-card>

          <div class="mt-4">
            <span class="text-caption"
              ><v-icon>mdi-format-columns</v-icon> Colunas</span
            >
            <div class="mt-2">
              <v-chip
                class="mr-1"
                :color="col.required ? 'red' : 'primary'"
                v-for="col in model.columns.filter((x) => x.id !== ' ')"
                :key="col.id">
                {{ col.name }}
                <span v-if="col.required" class="font-weight-black">*</span>
              </v-chip>
            </div>

            <div class="mt-4 mb-5">
              <v-table class="pb-4 mb-3 overflow-none">
                <thead>
                  <tr>
                    <th v-for="(col, index) in model.cols" :key="index">
                      <v-autocomplete
                        :items="columns"
                        v-model="col.id"
                        class="mr-2"
                        outlined
                        dense
                        hide-details
                        clearable
                        density="comfortable"
                        style="min-width: 200px !important"
                        :title="col.id"
                        item-value="id"
                        item-text="name">
                        <template #label>
                          <label>{{
                            col.id ? retornarNomeColunaSelecionada(col.id) : ''
                          }}</label>
                        </template>

                        <template v-slot:item="{ props, item }">
                          <v-list-item
                            v-bind="props"
                            :title="item.raw.name"></v-list-item>
                        </template>
                      </v-autocomplete>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, index) in model.rows" :key="index">
                    <tr>
                      <td
                        v-for="column in model.cols"
                        :key="column.index"
                        style="max-width: 200px"
                        class="text-truncate"
                        :title="row.cols[column.index]">
                        {{ row.cols[column.index] }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-table>

              <v-alert
                class="mb-4 mt-n4"
                v-if="model.upload && model.rows.length === 0"
                density="compact"
                variant="tonal"
                text="O arquivo que você importou não contém nenhum registro, verifique o arquivo e tente novamente."
                title="Nenhum registro encontrado"
                type="warning"></v-alert>

              <div v-if="model.upload" class="text-caption">
                <span
                  >O arquivo <strong>{{ model.fileName }}</strong> contém
                  <strong>{{ model.totalCols }}°</strong> Colunas.</span
                >
                <br />
                <span class="text-red caption"
                  ><v-icon small color="red">mdi-information</v-icon> As colunas
                  marcadas em vermelho são obrigatórias para a importação.</span
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-4"
          v-if="model.upload"
          variant="elevated"
          :loading="loadingBtnConcluirImportacao"
          color="primary"
          @click.prevent="aoClicarImportar"
          :disabled="desabilitarBtnConcluir"
          ><v-icon>mdi-upload</v-icon> Concluir Importação</v-btn
        >
        <v-btn class="mr-4" @click="onHandlerClose"
          ><v-icon>mdi-close</v-icon> Fechar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  watch,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  defineProps,
  defineEmits,
} from 'vue';
import HttpClientService from '@/core/http/api.http.service';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const props = defineProps(['importId', 'dialog','title']);
const emitter = defineEmits(['fechar', 'importacao-andamento']);

const files = ref([]);
const firstRowIsHeader = ref(true);
const downloadModelo = ref(false);
const useConvertData = ref(false);
const useExecuteService = ref(true);
const loadingCancelar = ref(false);
const loadingBtnConcluirImportacao = ref(false);
const customBody = reactive({});
const model = reactive({
  requestId: '',
  fileName: '',
  upload: false,
  cols: new Array<any>([]),
  columns: new Array<any>([]),
  rows: new Array<any>([]),
  totalRows: 0,
  totalCols: 0,
  totalRowsPending: 0,
});

const dialog = computed(() => props.dialog);
const columns = computed(() => {
  if (model.cols && model.columns) {
    const cols = model.cols.filter((x) => x.id !== '').map((s) => s.id);

    const columns = model.columns.filter((x) => !cols.includes(x.id));
    return columns;
  }

  return [];
});

const desabilitarBtnConcluir = computed(() => {
  if (model.cols && model.columns) {
    const colsRequired = model.columns
      .filter((s) => s.required)
      .map((s) => s.id);

    const cols = model.cols.filter((x) => colsRequired.includes(x.id));

    const desabilitar = !(cols.length === colsRequired.length);
    return desabilitar;
  }

  return true;
});

function downloadModel() {
  downloadModelo.value = true;
  HttpClientService.download(
    `/importacao/excel-model/${props.importId}`,
    `MODELO_IMPORTACAO_${props.importId}`
  ).finally(() => {
    downloadModelo.value = false;
  });
}

function onHandlerClose() {
  emitter('fechar');
}

function aoClicarImportar() {
  loadingBtnConcluirImportacao.value = true;

  HttpClientService.post(`/importacao/importar/${model.requestId}`, {
    cols: model.cols,
    body: customBody,
  })
    .then((result) => {
      if (!result.success) {
        const errorMessages = extrairFieldsMessages(
          result.fields,
          result.messages
        );
        console.log('errorMessages', errorMessages);
        toast(`Não foi possível importar os dados no momento.`, {
          theme: 'colored',
          type: 'error',
          dangerouslyHTMLString: true,
        });
      } else {
        onHandlerGetContainer();
        onHandlerClose();
        onHandlerImportacaoReport(true);
      }
    })
    .finally(() => {
      loadingBtnConcluirImportacao.value = false;
    });
}

function extrairFieldsMessages(fields: any[], messages: any[]) {
  let data = new Array<any>();

  for (var field in fields) {
    const fieldObj = fields[field];
    data.push({
      id: fieldObj.id,
      text: fieldObj.message.text,
    });
  }

  for (var message in messages) {
    const msg = messages[message];
    data.push({
      id: msg.id,
      text: msg.text,
    });
  }

  return data;
}

function onHandlerCancelImport() {
  loadingCancelar.value = true;
  HttpClientService.post(`/importacao/cancelar/${model.requestId}`)
    .then((result) => {
      if (result.success) {
        toast('Importação Cancelada com sucesso.', {
          theme: 'colored',
          type: 'default',
          dangerouslyHTMLString: true,
        });
      } else {
        toast('Não foi possível cancelar a importação no momento.', {
          theme: 'colored',
          type: 'error',
          dangerouslyHTMLString: true,
        });
      }
    })
    .finally(() => {
      loadingCancelar.value = !loadingCancelar.value;
      onHandlerGetContainer();
    });
}

function retornarNomeColunaSelecionada(id: string) {
  const item = model.columns.find((s) => s.id == id);
  if (item) {
    return item.name;
  }

  return '';
}

function onSelectedFiles() {
  if (files.value.length === 0) {
    return;
  }

  uploadFiles()
    .then(() => {
      files.value = [];
      toast('Upload realizado com sucesso.', {
        theme: 'colored',
        type: 'success',
        dangerouslyHTMLString: true,
      });
    })
    .finally(() => {
      onHandlerGetContainer();
    });
}

function uploadFiles() {
  const url = `/importacao/upload/${props.importId}?executeService=${useExecuteService.value}&convertData=${useConvertData.value}&withHeader=${firstRowIsHeader.value}`;
  const formData = new FormData();
  [files.value].forEach((file: any) => {
    formData.append('import', file);
  });

  return HttpClientService.post<any>(url, formData);
}

function onHandlerImportacaoReport(isProcessing: boolean) {
  emitter('importacao-andamento', isProcessing);
}

function onHandlerGetContainer() {
  HttpClientService.get<any>(`/importacao/Contato`).then((result) => {
    if (result.success) {
      Object.assign(model, result.data);
      onHandlerImportacaoReport(result.data.isProcessing);
    }
  });
}

onMounted(() => {
  onHandlerGetContainer();
});
</script>
