<template>
 <div class="canal-icons-position">
  <img
    v-if="props.tipo === 1"
    width="20"
    src="@/assets/logos/whatsapp.png"
    alt="WhatsApp Business"
  />

  <img
    v-if="props.tipo === 2"
    width="20"
    src="@/assets/logos/whatsapp-business-api.png"
    alt="WhatsApp Business"
  />

  <img
    v-if="props.tipo === 3"
    width="20"
    src="@/assets/logos/Instagram_logo.png"
    alt="Instagram"
  />
 </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps(["tipo"]);
</script>


<style>
.canal-icons-position{
    position: relative;
    display: inline-block;
}
</style>
