<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99999;
      background: #ccc; /* From https://css.glass */
      background: rgba(255, 255, 255, 0);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    "
  >
    <div class="pr-2">
      <img src="@/assets/zapply.png" alt="Logo Zapply" width="100" />
    </div>
    <span class="text-h5">{{ titleName }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps(["title"]);

const titleName = computed(() => props.title || "Carregando atendimentos");
</script>
